<template>
  <v-container
    fluid
    style="height: 100%; max-width: 1500px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <agency-left-section class="hidden-md-and-down" v-if="type == 'Agency'" />
    <volunteer-left-section
      class="hidden-md-and-down"
      v-else-if="type == 'Volunteer'"
    />
    <admin-left-section v-else-if="type == 'Admin'" />
    <router-view />
    <agency-right-section class="hidden-sm-and-down" v-if="type == 'Agency'" />
    <volunteer-right-section
      class="hidden-sm-and-down"
      v-else-if="type == 'Volunteer'"
    />
    <admin-right-section
      class="hidden-sm-and-down"
      v-else-if="type == 'Admin'"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AdminLeftSection from "../../admin/AdminLeftSection.vue";
import AdminRightSection from "../../admin/AdminRightSection.vue";
import VolunteerLeftSection from "../../volunteer/VolunteerLeftSection.vue";
import VolunteerRightSection from "../../volunteer/VolunteerRightSection.vue";
import AgencyLeftSection from "../AgencyLeftSection.vue";
import AgencyRightSection from "../AgencyRightSection.vue";
export default {
  components: {
    // AgencyNeedMenuSection,
    AgencyLeftSection,
    AgencyRightSection,
    VolunteerLeftSection,
    VolunteerRightSection,
    AdminLeftSection,
    AdminRightSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      type: "type",
    }),
  },
};
</script>
<style scoped></style>
